body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rebeccapurple;
  background: -webkit-linear-gradient(
    #3c0c72,
    #521392,
    #70147a,
    #7d1361,
    #891774,
    #a01855,
    #ae1b5a,
    #be1d41,
    #c53b40,
    #c43d3e
  );
  background: linear-gradient(
    #3c0c72,
    #521392,
    #70147a,
    #7d1361,
    #891774,
    #a01855,
    #ae1b5a,
    #be1d41,
    #c53b40,
    #c43d3e
  );
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::placeholder {
  color: white;
}

:focus {
  outline: none;
}
